export const customerSettings = {
COMPANY_CODE: "demey",
MODE: "production",
ENV: "production",
VERSION: 1.1,
DOMAIN: 'garagedemey.be',
SCRIPTS: [],
FONTS: [],
SENTRY_DSN: "https://356d953cae7ac936b8693143d56d2cbd@o1290476.ingest.us.sentry.io/4507509201764352",
LANGUAGES: ['nl'],
FORCE_SINGLE_LANGUAGE_IN_URL: false,
WEBSITE_NAME: "",
IMAGE_PROMO_POSITION: "",
PATH_PREFIX: "",

GOOGLE_ANALYTICS_IDENTIFICATION: '',
GOOGLE_ANALYTICS_ID: '',
GOOGLE_TAG_MANAGER_ID: '',
MAPBOX_TOKEN: 'pk.eyJ1IjoiYXV0cmFsaXMiLCJhIjoiY2toeXR2emhwMDk0OTJ6cWh0Y3JjeG5kaSJ9.YCvAWa5Wyqof0wPdueve5w',
RECAPTCHA_KEY: '6LcZyaUZAAAAAOrMBxDUfdVDq2xKcHORceibmov3',
FINANCING: true,
FINANCING_TEXT: true,
RESERVATION: false,
FAVORITES: false,
VEHICLE_COMPARE: false,
SEO: true,
ACCOUNT: false
};